const dev = {
    WORKIZ_BOOKING_API: "https://dev.workiz.com/api/booking",
    BOOKING_APP_API_URL: "http://localhost:3001",
    STRIPE_CUSTOM_CONNECT_ACCESS_TOKEN: "pk_test_51K0ilQFvYIFEBpv96LV0HTlHladDzf4SVhMvHzMAqeKuWjEkzz1FdB6YNWqUVuQYWcccRpkoBwAyurMnmi4nb2PI00SYtYaAIU",
    AMPLITUDE_API_KEY: "c35649c780a09c865db81ee5c9f5377c",
    GOOGLE_CONVERSION_TRACKING_URL: "https://www.google.com/maps/conversion/debug/collect",
    CAPCHA_SITE_KEY: '6Lev1-QpAAAAAD6kC8Zy4edIhaa5sFV815DeF7FA',
    CAPCHA_SECRET: '6Lev1-QpAAAAAEoQJ_qqMeSlGeghzpznR7RyRwdC',
    CAPCHA_API: 'https://www.google.com/recaptcha/api/siteverify'
};

const staging = {
    WORKIZ_BOOKING_API: "https://booking.tests.workiz.com/api/booking",
    BOOKING_APP_API_URL: "https://booking-functions-staging.workiz.com",
    STRIPE_CUSTOM_CONNECT_ACCESS_TOKEN: "pk_test_VrS9deetaf6ouYZSstPdt4SH",
    AMPLITUDE_API_KEY: "c35649c780a09c865db81ee5c9f5377c",
    CAPCHA_SITE_KEY: '6LcNM-EqAAAAABpXYbPu3lGnw7Ea3a468nLhmkON',
    CAPCHA_SECRET: '6LcNM-EqAAAAALxI9YjRsjycVzjG7tMU0NLq8k40',
    CAPCHA_API: 'https://www.google.com/recaptcha/api/siteverify',
    GOOGLE_CONVERSION_TRACKING_URL: "https://www.google.com/maps/conversion/debug/collect"
};

const rc = {
    WORKIZ_BOOKING_API: "https://rc.workiz.com/api/booking",
    BOOKING_APP_API_URL: "https://booking-functions-rc.workiz.com",
    STRIPE_CUSTOM_CONNECT_ACCESS_TOKEN: "pk_test_VrS9deetaf6ouYZSstPdt4SH",
    AMPLITUDE_API_KEY: "c35649c780a09c865db81ee5c9f5377c",
    CAPCHA_SITE_KEY: '6LcNM-EqAAAAABpXYbPu3lGnw7Ea3a468nLhmkON',
    CAPCHA_SECRET: '6LcNM-EqAAAAALxI9YjRsjycVzjG7tMU0NLq8k40',
    CAPCHA_API: 'https://www.google.com/recaptcha/api/siteverify',
    GOOGLE_CONVERSION_TRACKING_URL: "https://www.google.com/maps/conversion/debug/collect"
};

const prod = {
    WORKIZ_BOOKING_API: "https://api.workiz.com/api/booking",
    BOOKING_APP_API_URL: "https://booking-functions.workiz.com",
    STRIPE_CUSTOM_CONNECT_ACCESS_TOKEN: "pk_live_znq8y1I8dlSbNdR144bX0i91",
    AMPLITUDE_API_KEY: "6bc83c6e638777a30428eb63b4a5068f",
    CAPCHA_SITE_KEY: '6LdbUeEqAAAAAM5-N6nbeD64Fquv4ANYH0Zs1Zt4',
    CAPCHA_SECRET: '6LdbUeEqAAAAAGm5AOEs2_qP4ptK7C-ktM2K6NVR',
    CAPCHA_API: 'https://www.google.com/recaptcha/api/siteverify',
    GOOGLE_CONVERSION_TRACKING_URL: "https://www.google.com/maps/conversion/collect"
};

const config = () => {
        switch(process.env.REACT_APP_STAGE) {
            case 'prod':
              return prod
              break;
            case 'rc':
                return rc;
                break;
            case 'staging':
               return staging
               break;
            case 'dev':
                return dev;
                break;
            default:
              return dev;
          }
    }
export default config();
