import axiosInstance from "@api/axios"
import config from "../config";
import store from '../store';
import { amplitudeEvent } from '@api/amplitude';

export const updateForm = (payload) => async dispatch => {
    dispatch({type: 'UPDATE_FORM', payload: payload});
};

export const uploadFiles = (payload) => async dispatch => {
    const promise = axiosInstance.post(`${config.BOOKING_APP_API_URL}/upload`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })

    return promise.then((res) => {
        const baseUrl = 'https://storage.googleapis.com/booking-files/'
        let filesUrls = []
        for (let i in res.data) {
            filesUrls.push(baseUrl + res.data[i])
        }
        return filesUrls
    })
};

export const getWePayToken = (payload) => async dispatch => {
    //get wepay token
    let wePayPayload = {
        'client_id': '123102',
        "user_name": payload.holder_name,
        "email": payload.email,
        "cc_number": payload.cc_number,
        "cvv": payload.cvc,
        "expiration_month": payload.expiration_month,
        "expiration_year": payload.expiration_year,
        "address": {
            "country": payload.address.country,
            "postal_code": payload.address.postal_code,
        },
        "card_on_file": true,
        "recurring": false
    }
    if(process.env.REACT_APP_STAGE !== 'prod' && window.WePay){
        window.WePay.set_endpoint("stage");
    }


    return new Promise(resolve => {

        window.WePay.credit_card.create(wePayPayload, (res) => {
            if (res.error_code && res.error_code === 1003) {
                resolve(res)
            } else {
                resolve(res.credit_card_id)
            }
        })
    });

}

export const createClient = (payload) => async dispatch => {
    const userDetails = {
        email_address: payload.email,
        first_name: payload.first_name,
        last_name: payload.last_name,
        primary_phone: payload.phone?.replace(/[-() ]/g, ''),
        address: payload.address,
        zip: payload.zip,
        city: payload.city,
        state: payload.state,
        country: payload.country,
        bookingVersion: 2,
    }

    const bookingPayload = {
        ac: payload.ac,
        uc: "-1",
        userDetails
    }

    const response = await axiosInstance.post(`${config.WORKIZ_BOOKING_API}/submitClient/`, bookingPayload)
    return {
        isNewClient: response.data?.isNewClient,
        id: response.data.id,
        hash: response.data.client?.hash
    }
}
const sendConversionTracking = (rwgToken) => {
    const endpoint = config.GOOGLE_CONVERSION_TRACKING_URL;
    const partnerId = 20002958;
    fetch(endpoint, {
            method: "POST",
            body: JSON.stringify({
              conversion_partner_id: partnerId,
              rwg_token: decodeURIComponent(rwgToken),
              merchant_changed: 2
            })
          });
}

export const saveBooking = (payload) => async dispatch => {
    const storeData = store.getState();
    if(payload.phone){
        payload.phone = payload.phone.replace(/\+|\-/gm,'')
    }
    const userDetails = {
        amount: payload.amount,
        deposit: payload.deposit,
        email_address: payload.email,
        first_name: payload.first_name,
        last_name: payload.last_name,
        primary_phone: payload.phone?.replace(/[-() ]/g, ''),
        totalAmount: payload.totalAmount,
        numOfServices: payload.numOfServices,
        address: payload.address,
        zipcode: payload.zip,
        city: payload.city,
        state: payload.state,
        country: payload.country,
        description: payload.description,
        job_date: payload.job_date,
        job_end_date: payload.job_end_date,
        services: payload.services,
        hasPayment: payload.hasPayment,
        bookingVersion: 2,
        files: payload.files,
        client_id: payload.clientId ?? -1,
        ad_group_name: payload.ad_group_name ?? "",
        job_type: payload.job_type,
        job_type_name: payload.job_type_name,
    }

    for(let key in payload.customFieldsKeys){
        userDetails[key] = payload.customFieldsKeys[key]
    }
    const params = {
        numItems: storeData?.servicesTotals?.numOfServices,
        totalPrice: (storeData?.servicesTotals?.totalAmount + storeData?.servicesTotals?.totalTax).toFixed(2),
        depositPrice: payload.deposit,
        accountId: storeData?.account?.accountId,
        itemsJson: JSON.stringify(payload.services.map(service => service.id)),
        paymentsEnabled: payload.hasPayment,
        itemsEnabled: payload.services ? true : false,
        attachments: payload.files.length,
        jobTypeId: payload.job_type,
        jobTypeName: payload.job_type_name,
        serviceTypeUsed: storeData?.selectedJobType ? true : false
    };
    amplitudeEvent('bookingCompletedOrder', params);
    const url = new URL(window.location.href);
    const urlParams = new URLSearchParams(url.search);
    const fromGLS = urlParams.has('fromgls') && urlParams.get('fromgls') === 'true';
    const ac = urlParams.get('ac');
    const rwgTokenObjFromLS = JSON.parse(localStorage.getItem('rwg_token_v2'))?.[ac];
    const thirtyDays = 30 * 24 * 60 * 60 * 1000;
    const rwgTokenFromLS = rwgTokenObjFromLS?.time && (Date.now() - rwgTokenObjFromLS.time) < thirtyDays ? rwgTokenObjFromLS?.rwgToken : false;
    const rwgToken = urlParams.get('rwg_token') || rwgTokenFromLS;
    const bookingPayload = {
        ac: payload.ac,
        uc: "-1",
        ad_group: payload.ad_group,
        customFields: payload.customFields,
        userDetails,
        fromGLS: fromGLS
    }
    await axiosInstance.post(`${config.BOOKING_APP_API_URL}/addLogs`, {action: 'addLogs', data: bookingPayload});
    if(rwgToken)
        sendConversionTracking(rwgToken);
    const response = await axiosInstance.post(`${config.WORKIZ_BOOKING_API}/submitBooking/`, bookingPayload)
    return {
        id: response.data.id,
        hash: response.data.client?.hash
    }
}

export const saveClientCardToken = (payload) => async dispatch => {

    const saveCardPayload = {
        "account_id": payload.account_id,
        "client_id": payload.client_id,
        "card_token": payload.card_token,
        "exp_month": "" + payload.exp_month,
        "exp_year": "" + payload.exp_year,
        "card_type": payload.card_type,
        "hash": payload.hash,
        "last_4_digits": payload.last_4_digits,
        "card_cvv": payload.card_cvv
    }

    const promise = axiosInstance.post(`${config.WORKIZ_BOOKING_API}/saveCardOnFile/`, saveCardPayload)
    return promise.then((promise) => promise)
}

export const getSetupIntent = ({clientId, accountId}) => async dispatch => {
    const setupIntentPayload = {
        "client_id": clientId,
        "account_id": accountId
    }
    const response = await axiosInstance.post(`${config.WORKIZ_BOOKING_API}/createSetupIntent/`, setupIntentPayload);
    return response.data;
};
